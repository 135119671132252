.login-container {
  height: 100vh;
  .row {
    height: 100%;
  }
}
.login-section{
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(22,62,61,1) 100%);
  }
  &.right {
    // background-color: black;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(22,62,61,1) 100%);
    .login-box-msg{
      color: white;
      font-size: 26px;
    }
    form {
      width: 80%;
    }
  }
}